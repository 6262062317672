@import "../../App.scss";

.shopBtn {

    background-color: red;
    display: block;

    background-color: #fafafa;
    transition: color .1s ease-in-out, background-color .1s ease-in-out;

    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;

    &:hover:not([disabled]) {
        background-color: $teleco-link-color;
        color: white;
    }

    &[disabled] {
        cursor: not-allowed;
        color: gray;
    }

    &.block {
        display: block;
        width: 100%;
    }
}

input.form-control {
    margin-bottom: 10px;
}
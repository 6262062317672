/* 
      <div className="purchasechoice">
      <p className="name">Avbetalning 24 mån</p>
      <p className="price">Förhöjd avgift</p>
      <p className="description">med abonnemang</p>
    </div> */

@import "../../App.scss";

.purchasechoice {
    border: 1px solid $teleco-color;
    height: 45px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 40px;
    cursor: pointer;

    &.selected {
        background: $teleco-color;
        color: white;
    }
    p {
        margin: 0;
        position: absolute;
    }
    
    .price {
        right: 10px;
    }

    .description {
        bottom: 3px;
        font-size: 0.8em;
    }

    input {
        margin-bottom: 5px;
    }
}


.product {
    h1 {
        text-align: center;
    }

    .image {
        text-align: center;
    }
}

.productMeta {
    text-align: center;
}

.specification {
    text-align: center;

}


// border: "1px solid $teleco-color", borderRadius: 0

.subBtn {
    color: black;
    border: 1px solid $teleco-color;
    border-radius: 0;
    margin-bottom: 10px;

    position: relative;
    padding: 6px;


    &.selected {
        color: white;
        background: $teleco-color;

        .icon {
            color: white;
        }
    }

    .icon {
        color: $teleco-color;
    }

    .wrapper {
        position: absolute;
        display: table;
        top: 0;
        bottom: 0;
        left: 47px;
        right: 115px;
        height: 46px;

        p {
            display: table-cell;
            vertical-align: middle;
            line-height: 14px;
        }
    }

    .price {
        position: absolute;
        right: 6px;
        font-size: 23px;
    }
}

.subchoiceform{
    margin-bottom: 10px;
}

// .cardpaymentinitiated {
//     position: relative;

//     &::after {
//         content: '';
//         position: absolute;
//         left: 0;
//         right: 0;
//         top: 0;
//         bottom: 0;
//         background: rgba($color: #000000, $alpha: 0.05);
//     }
// }

.specificationTable {
    text-align: left;
    font-weight: bold;
    line-height: 20px;

    table {
        margin: 0 auto;
        width: 500px;
    }
}

.deliveryinformation {
    table {
        margin: 0 auto;
    }
}

.display-8 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
}
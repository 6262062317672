@import "../../App.scss";

.teco__radiogroup {

    .teco__radio {


        display: block;
        position: relative;

        input {
            opacity: 0;
            position: fixed;
            width: 0;

            +label {}

            &:checked+label {
                background-color: white;

                >div {
                    border: 2px solid black;
                }
            }
        }

        label {
            display: grid;
            grid-template-columns: minmax(0, 100%) auto;
            background-color: #fafafa;
            transition: color .1s ease-in-out, background-color .1s ease-in-out;

            cursor: pointer;
            padding: 16px 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;

            vertical-align: middle;

            span {
                line-height: 1;
                display: flex;
                align-items: center;

                &.right {
                    flex-direction: row-reverse;

                    .originalPriceSpan {
                        color: red;
                        text-decoration: line-through;
                        margin-right: 10px
                    }
                }
            }

            >div {
                position: absolute;
                z-index: 2000;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 4px;
            }
        }
    }
}
@import "./shopapp/App.scss";

button.btnLink {
    padding: 0;
    border: 0;
    background: 0;
    color: $teleco-link-color;
    cursor: pointer;

    // &:visited {
    //     color 
    // }

    transition: color .1s ease-in-out, background-color .1s ease-in-out;
    text-decoration: none;

    &:hover {
        color: $teleco-link-color-hover;
    }
}

.no-margin {
    .form-group {
        margin-bottom: 0;
    }
}